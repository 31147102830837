.button {
  padding: 1rem 3rem;
  margin: 1rem;
  font-size: 2rem;
  border: 1px solid #39b15a;
  font-family: "Space Grotesk", sans-serif;
  text-decoration: none;
  color: #39b15a;
  font-weight: 500;
  border-radius: 1rem;
}
.button:hover {
  background-color: #39b15a;
  color: black;
  box-shadow: 0px 0px 24px 0px #2ba24dcb;
}
