.hamburger-icon {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 100;
  width: 3rem;
  cursor: pointer;
  display: none;
  transition: all 100ms ease-in-out;
}
.hamburger-icon.open .line:first-child {
  translate: 0 5px;
  rotate: 45deg;
}
.hamburger-icon.open .line:last-child {
  rotate: -45deg;
  translate: 0 -7px;
}
.hamburger-icon.open .line:nth-child(2) {
  translate: 200px;
}
.line {
  content: "";
  width: 100%;
  margin: 3px 0px;
  border-radius: 2rem;
  height: 3px;
  background-color: #39b15a;
}
@media screen and (max-width: 700px) {
  .hamburger-icon {
    display: unset;
  }
}
