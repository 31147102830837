@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
.nav-holder {
  padding-top: 60px;
  padding-left: 40px;
}
html {
  font-family: "Space Grotesk", sans-serif;
  /* background-image: url(./Assets/bg.png); */
  /* position: absolute; */
  /* overflow-y: none; */
  /* position: static; */
  background: url(./Assets/grid.png) no-repeat center center fixed;
  background-color: #1e1e1e;
  -webkit-background-size: ;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-size: auto;

  min-height: 100vh;
}
.bg-holder {
  background-color: #080f13;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.container {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.test {
  position: absolute;
  z-index: 99;
}
.ctf-holder {
  text-align: center;
}
.logo {
  margin-top: -15px;
  width: 400px;
}
.date {
  color: white;
  margin-top: -20px;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
  color: #8fc09c;
}
.red {
  background-color: red;
  color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 13px;
}
.yellow {
  background-color: yellow;
  color: yellow;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 13px;
}
.green {
  background-color: #02e05f;
  color: #02e05f;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 13px;
}
.t-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #b1e2be1a;
}
.t-bar > * {
  flex: 1;
}
.t-buttons {
  display: flex;
  width: fit-content;
}
.t-header {
  font-family: "Space Grotesk", sans-serif;
  color: rgba(255, 255, 255, 0.18);
  font-size: 1.4rem;
  text-align: center;
  width: fit-content;
  justify-content: center;
}
.terminal {
  margin: 5px auto;
  /* justify-content: center; */
  background-color: #070707;
  opacity: 0.9;
  width: 60vw;
  box-shadow: 0px 0px 24.686px 0.949461px #2ba24d23;
  border-radius: 15.1914px;
}
.t-text {
  color: white;
  text-align: center;
  font-size: 1.7rem;

  font-weight: 400;
  font-family: "Space Grotesk", sans-serif;
}
.t-body {
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 2%;
  padding-bottom: 2%;
}
.coming {
  margin: 30px auto;
  /* cursor: pointer; */
  background-color: #4244b4;
  border-color: #4244b4;
  border-style: solid;
  font-size: 18px;
  color: white;
  border-radius: 10px;
  padding: 10px 40px;
}
.coming_soon {
  color: white;
  text-align: center;
  width: 100%;
  font-family: "Space Grotesk", sans-serif;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.right_top_corner {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #39b15a;
  font-family: "Space Grotesk", sans-serif;
  width: 200px;
}
.right_top_corner > p {
  display: flex;
}
.right_top_corner > P > * {
  margin-right: 5px;
}
.countdown_container {
  padding: 20px;
}

.sponsors-arrow {
  z-index: 10;
  color: #39b15a;

  position: relative;
  top: 2rem;
  font-family: "Space Grotesk";
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.sponsors-arrow > h1 {
  font-size: 1rem;
}

.arrow-container {
  position: relative;
  width: 100px;
  transition: all 0.2s;
}

.arrow {
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 1px solid #39b15a;
  border-left: none;
  border-top: none;
}
.arrow:first-child {
  transform: translate(-50%, -20%) rotate(45deg);
  transition: all 0.2s;
}

.sponsors-arrow:hover .arrow:first-child {
  transform: translate(-50%, -10%) rotate(45deg);
}

.sponsors-arrow:hover .arrow-container {
  transform: translate(0, 10px);
}
.socials {
  position: fixed;
  bottom: 0;
  right: 5rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 200ms ease-in-out;
  z-index: 10;
}

@media screen and (max-width: 850px) {
  .right_top_corner {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  html {
    font-size: 80%;
  }
  .gdsc-logo {
    width: 250px;
  }
  .nav-holder {
    padding-top: 25px;
    padding-left: 25px;
  }
  .logo {
    margin-top: 40px;
    width: 250px;
  }
  .date {
    color: white;
    margin-top: -35px;
    font-size: 1.5rem;
  }
  .t-bar {
    padding: 15px;
  }
  .terminal {
    width: 90vw;
  }
  .t-text {
    font-size: 1.4rem;
  }
  .t-body {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .socials {
    right: 2rem;
    transform: translateX(300%);
  }
  .socials.open {
    transform: translateX(0);
  }
}
