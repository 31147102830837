.timer {
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
  display: flex;
  width: fit-content;
  align-items: flex-start;
  color: #42cf6a;
  font-family: "Space Grotesk", sans-serif;
  width: 100%;
  justify-content: center;
}
.timer_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px;
}
.timer > p {
  font-size: 3rem;
  font-weight: 700;
  margin: 2px 5px 0 5px;
}
.timer_column > h1 {
  margin: 0;
  font-size: 3rem;
}
.timer_column > p {
  margin: 0;
  font-size: 1.4rem;
}
@media screen and (max-width: 756px) {
  .timer {
    width: 100%;
    justify-content: center;
  }
}
