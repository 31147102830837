#sponsors {
  margin-top: 20vh;
  height: 100vh;
  width: 100vw;
  color: white;
  font-family: "Space Grotesk";
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sponsors-header {
  height: 3rem;
  font-size: 3rem;
  color: #39b15a;
}

.logo-image {
  width: 80%;
  background-color: white;
  height: fit-content;
  display: flex;
  padding: 2rem;
  border-radius: 1rem;
}
.logo-image img {
  width: 100%;
  height: 100%;
}

.sponsors-logo {
  width: clamp(300px, 70%, 1000px);
  height: 50vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

.sponsor-title {
  font-size: 2rem;
  text-align: center;
}

.sponsor-tag {
  width: 400px;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-image > a {
  width: 100%;
  height: 100%;
}
