.social-icon {
  background-color: #39b15a;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  box-shadow: 0px 0px 24px 0px #2ba24d38;
}
.social-icon img {
  width: 100%;
  padding: 10px;
}
